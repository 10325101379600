"use strict";

var redactor = {
  version: 'redactor version 0.1',
  view: {},
  block: {},
  model: {},
  component: {},
  init: function init() {
    console.log('redactor.init ', this.version);
    this.createView();
    this.block.init();
    this.component.title.init();
    this.component.text.init();
    this.component.button.init();
    this.component.image.init();
    riot.mount('editor-ux-navbar');
  },
  createView: function createView() {
    // глобальный объект
    this.view.window = window;
    this.view.$window = this.view.window.$(this.view.window); // документ

    this.view.document = document;
    this.view.$document = $(document); // узел body

    this.view.$body = this.view.$document.find("body");
    this.view.body = this.view.$body[0]; // обёртка редактора .ux-redactor

    this.view.$redactor = this.view.$document.find(".ux-redactor");
    this.view.redactor = this.view.$redactor[0]; // обёртка списка блоков для шапки  .ux-redactor > .ux-header-list

    this.view.$headerList = this.view.$redactor.find("> .ux-header-list"); // обёртка списка блоков .ux-redactor > .ux-block-list

    this.view.$blockList = this.view.$redactor.find("> .ux-block-list");
    this.view.blockList = this.view.$blockList[0]; // обёртка списка блоков модальных окон .ux-redactor > .ux-modal-list

    this.view.$modalList = this.view.$redactor.find("> .ux-modal-list"); // обёртка списка блоков виджетов окон .ux-redactor > .ux-modal-list

    this.view.$widgetList = this.view.$redactor.find("> .ux-widget-list"); // обёртка списка блоков для подвала .ux-redactor > .ux-footer-list

    this.view.$footerList = this.view.$redactor.find("> .ux-footer-list"); //this.view.$frontendCss = this.view.$document.find(".frontend-css");
    //this.view.frontendCss = this.view.$frontendCss[0];

    this.model.id = this.view.redactor.dataset.id;
    this.model.title = this.view.redactor.dataset.title;
    this.model.csrf = this.view.redactor.dataset.csrf;
  }
};
redactor.api = {
  path: {
    load: '/backend/redactor/load/',
    save: '/backend/redactor/save/'
  },
  init: function init() {},
  getBlockList: function getBlockList() {
    return [{
      id: 'h0',
      title: 'Шапка 1',
      descr: 'Шапка',
      img: '/img/header0.jpg'
    }, {
      id: 'h1',
      title: 'Шапка 2',
      descr: 'Шапка',
      img: '/img/header0.jpg'
    }, {
      id: 'm0',
      title: 'Меню',
      descr: 'Меню 1',
      img: '/img/menu0.jpg'
    }, {
      id: 'b0',
      title: 'Блок 1',
      descr: 'Заголовок',
      img: '/img/block0.jpg'
    }, {
      id: 'b1',
      title: 'Блок 1',
      descr: 'Текстовый блок с заголовком',
      img: '/img/block1.jpg'
    }, {
      id: 'b2',
      title: 'Блок 2',
      descr: 'Текстовый блок с заголовком',
      img: '/img/block2.jpg'
    }, {
      id: 'b3',
      title: 'Блок 3',
      descr: 'Текстовый блок с заголовком',
      img: '/img/block3.jpg'
    }, {
      id: 'b4',
      title: 'Блок 4',
      descr: 'Текстовый блок с заголовком',
      img: '/img/block4.jpg'
    }, {
      id: 'b5',
      title: 'Блок 3',
      descr: 'Текстовый блок с заголовком',
      img: '/img/block5.jpg'
    }, {
      id: 'b6',
      title: 'Блок 4',
      descr: 'Обложка с кнопкой',
      img: '/img/block6.jpg'
    }, {
      id: 'b7',
      title: 'Блок 4',
      descr: 'Текстовый блок с заголовком',
      img: '/img/block7.jpg'
    }, {
      id: 'b8',
      title: 'Блок 8',
      descr: 'Текстовый блок с заголовком',
      img: '/img/block8.jpg'
    }, {
      id: 'b9',
      title: 'Блок 9',
      descr: 'Текстовый блок с заголовком',
      img: '/img/block9.jpg'
    }, {
      id: 'b10',
      title: 'Блок 9',
      descr: 'Текстовый блок с заголовком',
      img: '/img/block10.jpg'
    }, {
      id: 'b11',
      title: 'Блок 9',
      descr: 'Текстовый блок с заголовком',
      img: '/img/block11.jpg'
    }, {
      id: 'b12',
      title: 'Блок 12',
      descr: 'Блок в три колонки',
      img: '/img/block12.jpg'
    }, {
      id: 'b13',
      title: 'Блок 13',
      descr: 'Блок в четыре колонки',
      img: '/img/block13.jpg'
    }, {
      id: 'b14',
      title: 'Блок 14',
      descr: 'Блок в четыре колонки',
      img: '/img/block14.jpg'
    }, {
      id: 'b15',
      title: 'Блок 15',
      descr: 'Блок в четыре колонки',
      img: '/img/block15.jpg'
    }, {
      id: 'b16',
      title: 'Блок 16',
      descr: 'Блок заявки в 2 колонки',
      img: '/img/block16.jpg'
    }, {
      id: 'f1',
      title: 'Подвал 1',
      descr: 'Блок в четыре колонки',
      img: '/img/footer1.jpg'
    }, {
      id: 'c0',
      title: 'Карточки 0',
      descr: 'Карточки',
      img: '/img/card0.jpg'
    }, {
      id: 'c1',
      title: 'Карточки 1',
      descr: 'Карточки',
      img: '/img/card0.jpg'
    }];
  },
  load: function load() {
    console.log('load'); // this.view.$redactor.
  },
  save: function save() {
    //console.log('save');
    console.log("Save");
    var data = {
      'html': redactor.view.blockList.innerHTML,
      '_csrf': redactor.model.csrf
    };
    $.ajax({
      url: '/backend/redactor/save/?id=' + redactor.model.id,
      type: 'POST',
      data: data
    }).done(function (data) {
      if (data.success) {
        // данные сохранены
        console.log('Данные сохранены');
      } else {
        // сервер вернул ошибку и не сохранил наши данные
        console.log('сервер вернул ошибку и не сохранил наши данные');
      }
    }).fail(function () {
      // не удалось выполнить запрос к серверу
      console.log('не удалось выполнить запрос к серверу');
    });
  },
  insertFirstBlockTemplate: function insertFirstBlockTemplate(id) {
    var url = '/block/' + id + '.html';
    var html = '';
    var dataType = 'html';
    $.get(url, function (data) {
      //  передаем и загружаем данные с сервера с помощью HTTP запроса методом GET
      console.log('redactor.view.$blockList', redactor);
      redactor.view.$blockList.prepend(data); //redactor.block.$addFirst && redactor.block.$addFirst.remove();
      // console.log('get' , data, redactor.block.currentBlock );
    }, dataType);
  },
  insertBlockTemplate: function insertBlockTemplate(id) {
    var url = '/block/' + id + '.html';
    var html = '';
    var dataType = 'html';
    $.get(url, function (data) {
      //  передаем и загружаем данные с сервера с помощью HTTP запроса методом GET
      console.log('get success', redactor.block.currentBlock);

      if (redactor.block.currentBlock !== "null") {
        console.log('edactor.block.currentBlock', redactor);
        $(redactor.block.currentBlock).after(data);
      } else {
        console.log('redactor.view.$blockList', redactor);
        redactor.view.$blockList.prepend(data); //redactor.block.$addFirst && redactor.block.$addFirst.remove();
      } // console.log('get' , data, redactor.block.currentBlock );

    }, dataType);
  }
};
redactor.block = {
  currentBlock: 'null',
  init: function init() {
    var _this = this;

    this.mountBlockAddButtonFirst();
    redactor.view.$blockList.off("mouseenter mousemove mouseover mouseout", ".block").on("mouseenter", ".block", function (e) {
      // заходит на элемент
      console.log('mouseenter', _this, e.currentTarget);
      _this.currentBlock = e.currentTarget;
      $(_this.currentBlock).addClass("in-hover"); // добавить кнопку добавления блока

      _this.mountBlockAddButton(); // добавить кнопки управления

    }).on("mouseleave", ".block", function (e) {
      // уходит с элемента
      console.log('mouseleave', e.currentTarget);
      var a = e.currentTarget;
      $(a).removeClass("in-hover");

      _this.unmountBlockAddButton();
    });
  },
  // монтирует кнопку добавления блока
  mountBlockAddButtonFirst: function mountBlockAddButtonFirst() {
    // первая кнопка добавить блок
    this.$addFirst && this.$addFirst.remove(), this.$addFirst = redactor.view.$redactor.find(" > .ux-block-add-first-wrap");
    this.$addFirst.length || (this.$addFirst = $('<div class="ux-block-add-first-wrap"></div>'), $(redactor.view.$redactor).prepend(this.$addFirst), riot.mount(this.$addFirst[0], "ux-block-add-first"));
  },
  // монтирует кнопку добавления блока в блок
  mountBlockAddButton: function mountBlockAddButton() {
    console.log('redactor.block.addBlockButton()');
    this.$add && this.$add.remove(), this.$add = $(this.currentBlock).find(" > .ux-block-add-wrap");
    this.$add.length || (this.$add = $('<div class="ux-block-add-wrap"></div>'), $(this.currentBlock).append(this.$add), riot.mount(this.$add[0], "ux-block-add", {
      $block: $(this.currentBlock)
    }));
    this.$cmd && this.$cmd.remove(), this.$cmd = $(this.currentBlock).find(" > .ux-block-control-wrap");
    this.$cmd.length || (this.$cmd = $('<div class="ux-block-control-wrap"></div>'), $(this.currentBlock).append(this.$cmd), riot.mount(this.$cmd[0], "ux-block-control", {
      $block: $(this.currentBlock)
    }));
  },
  unmountBlockAddButton: function unmountBlockAddButton() {
    console.log('redactor.block.unmountBlockAddButton');
    this.$add && this.$add.remove();
    this.$cmd && this.$cmd.remove();
  },
  mountAddBlockDialog: function mountAddBlockDialog() {
    console.log('openAddBlockDialog');
    this.$dialog && this.$dialog.remove(), this.$dialog = redactor.view.$body.find(" > .ux-block-add-dialog-wrap");
    this.$dialog.length || (this.$dialog = $('<div class="ux-block-add-dialog-wrap"></div>'), $(redactor.view.$body).append(this.$dialog), riot.mount(this.$dialog[0], "ux-block-add-dialog", {
      $dialog: this.$dialog,
      $block: $(this.currentBlock)
    }));
  }
};
redactor.component.title = {
  currentComponent: 'null',
  init: function init() {
    var _this2 = this;

    redactor.view.$blockList.off("mouseenter mouseleave mousemove mouseover mouseout click", ".component-title").on("mouseenter", ".component-title", function (e) {// заходит на элемент
      //console.log('mouseenter', this, e.currentTarget)
    }).on("mouseleave", ".component-title", function (e) {// уходит с элемента
      //console.log('mouseleave', e.currentTarget);
    }).on("click", ".component-title", function (e) {
      _this2.currentComponent = e.currentTarget; //$(this.currentComponent).addClass("in-hover");
      // уходит с элемента
      //console.log('click', e.currentTarget, this.currentComponent);

      _this2.mountEditor();
    });
  },
  mountEditor: function mountEditor() {
    redactor.modal.create({
      duplicate: !0,
      riotTag: 'editor-title',
      riotOpts: {
        scope: this.currentComponent
      },
      name: 'modal-component-title',
      title: 'Редактирование заголовка',
      width: 520,
      height: null,
      scrollable: !0,
      overlay: "overlay"
    });
  }
};
redactor.component.text = {
  currentComponent: 'null',
  currentComponentCss: ".component-text",
  init: function init() {
    var _this3 = this;

    redactor.view.$blockList.off("mouseenter mouseleave mousemove mouseover mouseout click", this.currentComponentCss).on("mouseenter", this.currentComponentCss, function (e) {
      // заходит на элемент
      console.log('mouseenter', _this3, e.currentTarget);
    }).on("mouseleave", this.currentComponentCss, function (e) {
      // уходит с элемента
      console.log('mouseleave', e.currentTarget);
    }).on("click", this.currentComponentCss, function (e) {
      _this3.currentComponent = e.currentTarget; //$(this.currentComponent).addClass("in-hover");
      // уходит с элемента

      console.log('click', e.currentTarget, _this3.currentComponent);

      _this3.mountEditor();
    });
  },
  mountEditor: function mountEditor() {
    console.log('mountEditor'); // this.$dialog && this.$dialog.remove(),
    //     this.$dialog = redactor.view.$body.find(" > .ux-block-add-dialog-wrap");
    // this.$dialog.length || (this.$dialog = $('<div class="ux-block-add-dialog-wrap"></div>'),
    //     $(redactor.view.$body).append(this.$dialog),
    //     riot.mount(this.$dialog[0], "editor-dialog", { $dialog: this.$dialog, title: 'Редактирование текста' , editableElement:  this.currentComponent }));

    redactor.modal.create({
      duplicate: !0,
      riotTag: 'editor-text',
      riotOpts: {
        scope: this.currentComponent
      },
      name: 'modal-component-text',
      title: 'Редактирование текста',
      width: 520,
      height: null,
      scrollable: !0,
      overlay: "overlay"
    });
  }
};
redactor.component.button = {
  currentComponent: 'null',
  currentComponentCss: ".component-button",
  init: function init() {
    var _this4 = this;

    redactor.view.$blockList.off("mouseenter mouseleave mousemove mouseover mouseout click", this.currentComponentCss).on("mouseenter", this.currentComponentCss, function (e) {
      // заходит на элемент
      console.log('mouseenter', _this4, e.currentTarget);
    }).on("mouseleave", this.currentComponentCss, function (e) {
      // уходит с элемента
      console.log('mouseleave', e.currentTarget);
    }).on("click", this.currentComponentCss, function (e) {
      e.preventDefault();
      e.stopPropagation();
      console.log('You have clicked the link.');
      _this4.currentComponent = e.currentTarget; //$(this.currentComponent).addClass("in-hover");
      // уходит с элемента

      console.log('click', e.currentTarget, _this4.currentComponent);

      _this4.mountEditor();
    });
  },
  mountEditor: function mountEditor() {
    console.log('mountEditor'); // this.$dialog && this.$dialog.remove(),
    //     this.$dialog = redactor.view.$body.find(" > .ux-block-add-dialog-wrap");
    // this.$dialog.length || (this.$dialog = $('<div class="ux-block-add-dialog-wrap"></div>'),
    //     $(redactor.view.$body).append(this.$dialog),
    //     riot.mount(this.$dialog[0], "editor-dialog", { $dialog: this.$dialog, title: 'Редактирование текста' , editableElement:  this.currentComponent }));

    redactor.modal.create({
      duplicate: !0,
      riotTag: 'editor-button',
      riotOpts: {
        scope: this.currentComponent
      },
      name: 'modal-component-button',
      title: 'Редактирование кнопки',
      width: 520,
      height: null,
      scrollable: !0,
      overlay: "overlay"
    });
  }
};
redactor.component.image = {
  currentComponent: 'null',
  currentComponentCss: ".component-image",
  init: function init() {
    var _this5 = this;

    redactor.view.$blockList.off("mouseenter mouseleave mousemove mouseover mouseout click", this.currentComponentCss).on("mouseenter", this.currentComponentCss, function (e) {
      // заходит на элемент
      console.log('mouseenter', _this5, e.currentTarget);
    }).on("mouseleave", this.currentComponentCss, function (e) {
      // уходит с элемента
      console.log('mouseleave', e.currentTarget);
    }).on("click", this.currentComponentCss, function (e) {
      _this5.currentComponent = e.currentTarget; //$(this.currentComponent).addClass("in-hover");
      // уходит с элемента

      console.log('click', e.currentTarget, _this5.currentComponent);

      _this5.mountEditor();
    });
  },
  mountEditor: function mountEditor() {
    console.log('mountEditor'); // this.$dialog && this.$dialog.remove(),
    //     this.$dialog = redactor.view.$body.find(" > .ux-block-add-dialog-wrap");
    // this.$dialog.length || (this.$dialog = $('<div class="ux-block-add-dialog-wrap"></div>'),
    //     $(redactor.view.$body).append(this.$dialog),
    //     riot.mount(this.$dialog[0], "editor-dialog", { $dialog: this.$dialog, title: 'Редактирование текста' , editableElement:  this.currentComponent }));

    redactor.modal.create({
      duplicate: !0,
      riotTag: 'editor-image',
      riotOpts: {
        scope: this.currentComponent
      },
      name: 'modal-component-text',
      title: 'Редактирование изображения',
      width: 420,
      height: null,
      scrollable: !0,
      overlay: "overlay"
    });
  }
};
redactor.modal = {
  list: {},
  create: function create(t) {
    var opts = $.extend({
      duplicate: !0,
      riotTag: null,
      riotOpts: {},
      name: null,
      title: null,
      width: null,
      height: null,
      scrollable: !0,
      overlay: "overlay"
    }, t);
    this.$dialog && this.$dialog.remove(), this.$dialog = redactor.view.$body.find(" > .ux-block-add-dialog-wrap");

    if (this.$dialog.length === 0) {
      this.$dialog = $('<div class="ux-block-add-dialog-wrap"></div>');
      $(redactor.view.$body).append(this.$dialog);
      riot.mount(this.$dialog[0], "editor-modal", Object.assign({
        $dialog: this.$dialog
      }, opts));
    }
  }
};